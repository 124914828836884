.layout-footer {
  background-color: #232528;
  color: #fff;
  display:block;
  position:fixed;
  bottom:0;
  left:0;
  right:0;
}

.layout-footer__inner {
  height: 50px;
  padding: 5px 0;
}

.panel-center {
  display: flex;
  align-items: center;
}
