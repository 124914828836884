.section-welcome {
  //height: 100%;
  margin:auto;

  .container {
    height: 100%;
    display: flex;
  }
}

.module-company {
  width: 382px;
  margin: auto;
}

.module-company__logo {
  background: url('/assets/images/sms-logo.svg') 50% 50% no-repeat;
  background-size: contain;
  display: block;
  width: 382px;
  height: 50px;
}

.module-company__slogan {
  text-align: right;
  font-size: 28px;
  line-height: 1.2;
}

.link-scan {
  color: #fff;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  @include transition(color 0.3s);
  padding: 10px 20px;
  margin: 0 auto;

  .icon-scan {
    fill: #fff;
    width: 19px;
    height: 19px;
    margin-left: 15px;
    @include transition(fill 0.3s);
  }

  &:hover {
    color: #a6a6a6;

    .icon-scan {
      fill: #a6a6a6;
    }
  }
}

.link-back {
  color: #fff;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  @include transition(color 0.3s);
  padding: 10px 20px;
  margin: 0 auto;

  .icon-back {
    fill: #fff;
    width: 27px;
    height: 19px;
    margin-right: 15px;
    @include transition(fill 0.3s);
  }

  &:hover {
    color: #a6a6a6;

    .icon-back {
      fill: #a6a6a6;
    }
  }
}

@media screen and (max-width:$table-screen) {
  .module-company {
    width: 300px;
  }
  
  .module-company__logo {
    width: 300px;
    height: 50px;
  }
  
  .module-company__slogan {
    font-size: 20px;
  }
}
@media screen and (max-width:$xs-mobile) {
  .module-company {
    width: 260px;
  }
  
  .module-company__logo {
    width: 260px;
    height: 40px;
  }
  
  .module-company__slogan {
    font-size: 16px;
  }
}
