.section-scan {
  overflow: hidden;
  position:fixed;
  top:0;
  right:0;
  bottom:50px;
  left:0;
  height:auto;
}
.section-scan__panel {
  height: 100%;
  background-color: #ccc;
  width: 100%;
  border: none;
  padding: 0;
  margin: 0;
  section {
    padding-top: 0px !important;
    height: 100%;
  }
  video {
    object-fit: cover;
  }
}

.video-tag,
.scan-video-block {
  display:block;
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  width:100%;
  height:100%;
}

.video-tag {
  //object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: auto;
  min-width: 100%;
  min-height: 100%;
}
.scan-video-block {
  background:#eee;
  &:after {
    content:'';
    display:block;
    position:absolute;
    $mar-self:50px;
    top:$mar-self;
    right:$mar-self;
    left:$mar-self;
    bottom:$mar-self;
    border:2px solid #f22;
    
    @media screen and (max-width:$table-screen) {
      $mar-self:25px;
      top:$mar-self;
      right:$mar-self;
      left:$mar-self;
      bottom:$mar-self;
    }
  }
}

@supports (-ms-ime-align: auto) {
  .section-scan__panel {
    video {
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      height: auto !important;
      width: auto !important;
      min-width: 100% !important;
      min-height: 100% !important;
    }
  }
}
