// colors
$base-color: #313131;
$base-bg: #fff;

$color-xp: #a6a6a6;

// link color
$color-link: #000;
$color-link-hover: $color-xp;

// fonts
$font-default: 'Roboto', sans-serif;

$red-color: #d80003;
$green-color: #43a83c;
$blue-color: #16d5db;
$orange-color: #ff8c19;

$color-title: #4a4a4a;
$color-success: #009688;

$table-screen: 768px;
$xs-mobile:374px;
