*,
*::before,
*::after {
  box-sizing: inherit;
}

:active,
:focus,
:hover {
  outline: 0;
}

html,
body {
  min-height: 100%;
  padding: 0;
}

html {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  overflow-y: auto;
  height:100%;
}

body {
  font-size: 14px;
  line-height: 1.28;
  min-width: 320px;
  font-family: $font-default;
  font-weight: 400;
  color: #000;
  -webkit-font-smoothing: antialiased;
  background-color: #fafafa;
}

a {
  text-decoration: none;
  @include transition(color 0.3s ease);
  color: $color-link;

  &:hover {
    color: $color-link-hover;
    text-decoration: none;
  }
}

.link-underline {
  text-decoration-skip-ink: auto;
}

p {
  margin: 15px 0;
}

p:empty {
  display: none;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

.svg-store {
  display: none;
  height: 0;
  overflow: hidden;
}

.layout {
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.layout-inner {
}

.layout-body {
  display:flex;
  flex-direction:column;
  //height: calc(100vh - 50px);
  height:auto;
  min-height:calc(100vh - 50px);
  position:relative;
}

.container {
  width: 100%;
  max-width: 1280px;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;
  margin-right: auto;
}
