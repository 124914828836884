.section-list {
  //height: 100%;
  padding-bottom:70px;

  .container {
    height: 100%;
    padding: 0;
  }
}

.section-list__head {
  padding: 90px 30px 35px;
}

.section-list__body {
  //overflow: hidden;
  //overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  //height: calc(100vh - 208px - 50px);
  padding: 2px 30px;
}

.paper-inner {
  padding: 15px;
  display: block;
}

.grid-default {
  margin: 0 -10px;
  display: flex;
  flex-wrap: wrap;
}

.grid-default__item {
  width: 100%;
  padding: 0 10px;
  margin-bottom: 15px;

  &.x1 {
  }

  &.x4 {
    width: 33.333%;
  }

  &.x6 {
    width: 50%;
  }

  &.x12 {
    width: 100%;
  }
  @media screen and (max-width:$table-screen) {
    &.x4 {
      width: 50%;
    }
  }
}

.app-item {
  display: block;
  padding: 20px;
  text-align: center;
  cursor: pointer;

  &:hover {
    .app-item__title {
      color: #a6a6a6;
    }
  }
}

.app-item__title {
  color: #1a1516;
  font-size: 25px;
  line-height: 1.15;
  @include transition(color 0.3s);
}

.app-item__icon {
  width: 160px;
  height: 160px;
  margin: 0 auto;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}


.app-item_icon-1 {
  background-image: url(/assets/images/apps/edoc.svg);
}

.app-item_icon-2 {
  background-image: url(/assets/images/apps/genius-cm.svg);
}

.app-item_icon-3 {
  background-image: url(/assets/images/apps/imms.png);
}

.app-item_icon-4 {
  background-image: url(/assets/images/apps/smart-alarm.svg);
}

.app-item_icon-5 {
  background-image: url(/assets/images/apps/caster-digital-twin.svg);
}

.app-item_icon-6 {
  background-image: url(/assets/images/apps/segment-advisor.svg);
}

.app-panel {
  height: 100%;
}


@media screen and (max-width:$table-screen) {
  .app-item__icon {
    width: 80%;
    padding:0 0 100%;
    margin:0 auto 10px;
    height:auto;
    background-size:contain;
  }
  .app-item__title {
    font-size: 20px;
  }
}
@media screen and (max-width:$xs-mobile) {
  .app-item__icon {
    width: 80%;
    padding:0 0 100%;
    margin:0 auto 10px;
    height:auto;
    background-size:contain;
  }
  .app-item__title {
    font-size: 14px;
  }
}

.text-message {
  display: block;
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
}


.details {
  margin-bottom: 30px;
}

.qrcode-text {
  display:block;
  line-height: 1.2;
  font-size: 16px;
  padding: 30px 20px;
  text-align:center;
}
